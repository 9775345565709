.bottom-alert {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: absolute;
  left: 0;
  top: -150%;
  right: 0;
  bottom: 0;
  z-index: 99;
  background-color: #FFFFFFCA;
}
.bottom-alert-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: var(--ion-color-secondary);
  border-top-left-radius: var(--okx-box-radius);
  border-top-right-radius: var(--okx-box-radius);
  padding: var(--ion-padding);
  color: var(--ion-color-white, #ffffff);
  max-height: 80vh;
  overflow-y: auto;
}
.bottom-alert-content * {
  color: var(--ion-color-white, #ffffff) !important;
}

.bottom-alert-content ion-text,
.bottom-alert-content .bottom-alert-buttons,
.voucher-code-input-wrapper {
  width: 85%;
}
.bottom-alert-content .one-button {
  width: 100%;
}

.bottom-alert-buttons{
  display: flex;
  justify-content: space-between;
}

.bottom-alert-content .close-alert-button {
  position: absolute;
  right: 0;
  margin-right: var(--ion-padding);
}
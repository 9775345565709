:root {
  --clickup-initial-height: 50px;
  --clickup-open-height: 50%;
}

.pill {
  display: inline-block;
  border: 1px solid var(--ion-color-primary);
  font-size: var(--okx-small-text-font-size);
  padding: 2px 5px;
  margin: 0 5px 5px 0;
  text-transform: uppercase;
  font-weight: 600;
}

.pill-action {
  cursor: pointer;
}

ion-content div.dash-layout {
  background-image: url(../../assets/images/backgrounds/main-bg-2.jpg);
  background-repeat: no-repeat;
  background-position: center 0;
  background-size: auto 100%;}

ion-content div.dash-layout::before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  height: 50%;
  z-index: 0;
  background-image: linear-gradient(rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 50%);
}

/* ion-content div.dash-layout::after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: 50%;
  z-index: 0;
  background-image: linear-gradient(rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 1) 100%);
} */

.dash-card {
  position: absolute;
  left: 30px;
  right: 30px;
  margin: 0;
  bottom: 20px;
  z-index: 1;
  --background: #ffffff;
  border-radius: var(--ion-card-radius);
  padding-bottom: 15%;
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.25);

}

.dash-menu {
  padding: 0 50px 50px;
}

.dash-menu .list-md {
  background: transparent;
}

.dash-menu ion-item {
  --color: var(--ion-color-primary);
  --background: trasparent;
  --background-hover: trasparent;
  --min-height: 32px;
  --border-color: var(
    --okx-color-border-dash
  ); /* This firstly variable of border-color replaced with border-color right below */
  --border-color: rgba(var(--ion-color-gray-rgb), 0.3);
}

.dash-card ion-card-title,
.dash-menu ion-item {
  white-space: pre-wrap;
}
.dash-card ion-card-title .title {
  color: #1770FF;
}
.dash-card ion-label{
  margin: 10px 0;
}

.dash-menu ion-label {
  text-align: center;
}

.dashboard-image {
  width: auto;
  height: 70px;
  position: absolute;
  bottom: -32px;
  left: 0;
  right: 0;
  margin: auto;
}

.small {
  background: #1770FF;
}

.user-screen-name{
  font-family: secondary-bold  !important;
}

.route-dashboard .absolute-content {
  background-color: var(--ion-color-black);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.delivery-button{
  width: 100%;
}
.delivery-button-white {
  width: 100%;
}

.delivery-button-white ion-button {
  justify-content: flex-start;
}

.delivery-button > ion-icon {
  width: 35px;
  flex: none;
}
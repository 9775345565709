.address-checking-box {
  min-height: 80px;
}

.route-delivery-address-check .absolute-content > div {
  position: absolute;
}

.web ion-content.route-delivery-address-check {
	--ion-color-base: transparent;
	--background: transparent;
}

.delivery-address-bg {
  background-repeat: no-repeat;
  background-position: 50% 100%;
  background-size: cover;
}
.location-search-input{
  color: var(--ion-color-primary) !important
}
.delivery-address-bg::before {
  content: attr(title);
  position: absolute;
  font-size: var(--okx-title-font-size);
  font-weight: bold;
  text-align: center;
  padding-top: 20px;
  display: block;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  height: 50%;
  z-index: 2;
  background-image: linear-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
}

.route-delivery-address-check .restaurant-card {
  top: 0;
}

.route-delivery-address-check .restaurant-card ion-card-content {
  height: 100%;
}



.web .delivery-address-bg::before {
  content: '';
}

.web #main > ion-content.route-delivery-address-check .delivery-option-label {
  position: absolute;
  top: 64px;
  left: 0;
  right: 0;
  text-align: center;
}

.post-code-input {
  display: flex;
}

.post-code-input ion-icon {
  margin-top: 10px;
  margin-right: 6px;
}

.native-input.sc-ion-input-md:invalid {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.native-input.sc-ion-input-md {
  padding-left: unset;
  padding-right: unset;
  -webkit-padding-start: var(--padding-start);
  padding-inline-start: var(--padding-start);
  -webkit-padding-end: var(--padding-end);
  padding-inline-end: var(--padding-end);
}
.native-input.sc-ion-input-md {
  border-radius: var(--border-radius);
  padding-left: 0;
  padding-right: var(--padding-end);
  padding-top: var(--padding-top);
  padding-bottom: var(--padding-bottom);
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  letter-spacing: inherit;
  text-decoration: inherit;
  text-indent: inherit;
  text-overflow: inherit;
  text-transform: inherit;
  text-align: inherit;
  white-space: inherit;
  color: inherit;
  display: inline-block;
  -ms-flex: 1;
  flex: 1;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  border: 0;
  outline: none;
  background: transparent;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.pac-container {
  background-color: #fff;
  position: absolute !important;
  z-index: 1000;
  border-radius: 2px;
  border-top: 1px solid #d9d9d9;
  font-family: Arial, sans-serif;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
}

.pac-logo:after {
  content: '';
  padding: 1px 1px 1px 0;
  height: 16px;
  text-align: right;
  display: block;
  background-image: url(https://maps.gstatic.com/mapfiles/api-3/images/powered-by-google-on-white3.png);
  background-position: right;
  background-repeat: no-repeat;
  background-size: 120px 14px;
}
.hdpi.pac-logo:after {
  background-image: url(https://maps.gstatic.com/mapfiles/api-3/images/powered-by-google-on-white3_hdpi.png);
}
.pac-item {
  cursor: default;
  padding: 0 4px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: 30px;
  text-align: left;
  border-top: 1px solid #e6e6e6;
  font-size: 11px;
  color: var(--ion-color-primary);
}
.pac-item:hover {
  background-color: #fafafa;
}
.pac-item-selected,
.pac-item-selected:hover {
  background-color: #ebf2fe;
}
.pac-matched {
  font-weight: 700;
}
.pac-item-query {
  font-size: 13px;
  padding-right: 3px;
  color: #000;
}
.pac-icon {
  width: 15px;
  height: 20px;
  margin-right: 7px;
  margin-top: 6px;
  display: inline-block;
  vertical-align: top;
  background-image: url(https://maps.gstatic.com/mapfiles/api-3/images/autocomplete-icons.png);
  background-size: 34px;
}
.hdpi .pac-icon {
  background-image: url(https://maps.gstatic.com/mapfiles/api-3/images/autocomplete-icons_hdpi.png);
}
.pac-icon-search {
  background-position: -1px -1px;
}
.pac-item-selected .pac-icon-search {
  background-position: -18px -1px;
}
.pac-icon-marker {
  background-position: -1px -161px;
}
.pac-item-selected .pac-icon-marker {
  background-position: -18px -161px;
}
.pac-placeholder {
  color: var(--ion-color-primary);
}

.autocomplete-label{
  font-weight: bold;
}

.add-address-fields ion-item > ion-text {
  width: 80px;
  font-weight: bold;
  color: var(--ion-color-black);
}

.add-address-fields ion-item > ion-input {
  font-weight: 500;
}

.address-error-icon{
  display: flex;
  justify-content: center;
  align-items: center;
}

.postcode-input {
  display: flex;
  width: 100%;
  align-items: center;
}

.postcode-input div {
  width: 100% !important;
  margin-left: 10px;
} 
.toggle {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}
.route-register  .box-holder{
  margin: 0;
  border: none ;
  padding: 0;
}
/* .route-register  .box-holder ion-list{
  border: 1px solid rgba(var(--ion-color-light-rgb), 1);
  padding: 10px;
  border-radius: 5px;
} */
.web #main > ion-content.route-register > .ion-padding {
  height: 85%;
}
.web #main > ion-content.route-register > .ion-padding .absolute-content {
  overflow-y: auto;
}

.route-register .date-picker-wrapper {
  border-bottom: 1px solid black;
}

.route-register .date-picker-wrapper .data-picker-input{
  border-bottom: none !important;
}

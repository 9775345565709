.mbsc-select-input.mbsc-control {
  width: 100%;
  color: var(--ion-color-dark);
  padding: 10px 8px 10px 0;
  border: 0;
  outline: none;
}

.mbsc-select-input.mbsc-control::placeholder {
  color: var(--ion-color-dark);
  opacity: 0.33;
}

.mbsc-ios .mbsc-fr-btn {
  color: var(--ion-color-primary, #3880ff);
  font-size: 17px;
}

.select-picker-label {
  font-size: var(--okx-default-font-size);
  color: var(--ion-color-primary);
  margin-top: 10px;
}

.select-picker-label--primary {
  color: var(--ion-color-primary, #3880ff);
}

.select-picker-label.select-picker-label--feedback {
  margin-left: 0;
}

.select-picker-input--feedback + input.mbsc-select-input.mbsc-control {
  color: var(--ion-color-gray);
  font-size: 10px;
  font-weight: normal;
  border-color: var(
    --ion-color-black,
    var(--ion-color-black, var(--ion-color-step-150, rgba(0, 0, 0, 0.13)))
  );
}

.mbsc-fr-btn-w .mbsc-fr-btn-s {
  border: 0;
}

.mbsc-ios.mbsc-fr-center .mbsc-fr-btn-w {
  border: 0;
}

.mbsc-ios.mbsc-fr-center .mbsc-fr-btn-cont {
  border-top: 1px solid
    var(
      --ion-item-border-color,
      var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13)))
    );
}

.mbsc-ios .mbsc-sc-whl-gr-3d .mbsc-sc-itm-3d {
  color: #a3a3a3;
  background-color: transparent;
}

.mbsc-ios .mbsc-sc-itm-sel {
  color: #000;
  color: var(--ion-color-primary, #3880ff) !important;
  background-color: #F8F8F8;
}
.mbsc-sc-itm-sel span{
  font-weight: 600;
}
.mbsc-fr-c {
  background-color: #F8F8F8;
}

.mbsc-sc-whl-o {
  background: transparent !important;
}
.mbsc-fr-w, .mbsc-fr-c {
  border: 0px solid black !important;
  border-radius: var(--okx-box-radius);
}
.mbsc-fr-w {

  border: 0px solid black !important;
  background-color: #F8F8F8 !important;
  border-radius: var(--okx-box-radius);
}
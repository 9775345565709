ion-modal.modal-classic.pup-modal {
  --width: 80%;
  --height: 80%;
}

.pup-map-wrapper {
  position: relative;
  margin: 20px auto;
  width: 80%;
  min-width: 200px;
}

.pup-map-wrapper::after {
  content: '';
  display: block;
  padding-top: 100%;
}

.terms-wrapper {
  display: flex;
  flex-direction: column;
}
.terms-wrapper p,
.terms-wrapper a,
.terms-wrapper b {
  margin: 0;
  font-size: var(--ion-font-size);
  font-weight: 600;
}

.terms-wrapper b {
  margin-top: 10px;
  display: block;
}

.success-background{
  background-color: var(--okx-success-background-color);
  display: flex;
  padding: calc(var(--ion-padding) / 2);
  border-radius: var(--okx-box-radius);
}

.success-background ion-icon {
  height: auto;
  width: 40px;
}
.subscription-terms-checkbox{
  --border-radius: 10%;
}

.promo-bar,
.promo-bar-scrim {
  position: absolute;
  position: fixed;
  z-index: 5;
}

/* .promo-bar-scrim {
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0,0,0,0.000000001);
} */

.promo-bar {
  left: 0;
  right: 0;
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: 40px;
  background: #1770FF url(../../assets/images/info-white.svg) no-repeat 5px 50%;
  background-size: 30px 30px;
}

.promo-bar.warning {
  background-image: url(../../assets/images/warning-white.svg);
}

.promo-bar.error {
  background-image: url(../../assets/images/error-white.svg);
}

.promo-bar-type {
  text-transform: uppercase;
  color: var(--ion-color-white);
}
.promo-bar-body{
  color: var(--ion-color-white);

}
.web .promo-bar {
  max-width: var(--okx-bsmodal-width-lg);
  margin-left: auto;
  margin-right: auto;
  top: 2%;
}

@media screen and (min-height: 615px) and (max-height: 722px) {
  .web .promo-bar {
    top: 7%;
  }
}

@media screen and (min-height: 722px) {
  .web .promo-bar {
    top: 15%;
  }
}

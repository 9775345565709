.message-wrapper {
  border-bottom: 1px solid var(--okx-border-color);
  padding-bottom: 10px;
  margin-bottom: var(--okx-box-margin-bottom);
  display: flex;
  align-items: center;
}
.new-message-icon {
  padding-right: 10px;
  font-size: 25px;
}
.message-wrapper .new-message-icon-dotted {
  font-family: var(--ion-font-primary);
  stroke: var(--okx-menu-icon-color);
  position: relative;
}
.message-wrapper .new-message-icon-dotted::after {
  content: '';
  height: 12px;
  width: 12px;
  background-color: red;
  position: absolute;
  top: 0px;
  right: 7px;
  border-radius: 50%;
}
.success-icon {
    font-size: 64px;
    padding-bottom: 32px;
  }
  
  .description {
    font-size: var(--okx-sectiontitle-font-size);
    padding-left: var(--ion-padding);
    padding-right: var(--ion-padding);
    padding-top: 16px;
  }
  
  .web ion-content.route-membership-completed .ion-padding {
    height: 60%;

  }
  
.web .route-cards .ion-padding ion-button:last-child {
  margin-bottom: 0;
}

.route-cards .card-wrapper ion-text,
.route-cards .card-wrapper .small-text {
  display: block;
}
.route-cards .card-wrapper .single-item {
  margin-bottom: 5px;
}

.cards-list-card {
  margin: 0 0.5px 15px 0.5px;
}

.cards-list-card-image {
  height: 50px;
  width: 50px;
  border-radius: 5px;
  margin-right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cards-list-card-image ion-icon {
  height: 25px;
  width: 25px;
  opacity: 0.8;
}
.no-cards-image {
  min-height: 100px;
  width: 100%;
  border-radius: 5px;
  margin-right: 15px;
  display: flex;
  justify-content: flex-start ;
  align-items: center;
}
.no-cards-image ion-icon {
  height: 40px;
  width: 40px;
  opacity: 0.8;
  padding: var(--ion-padding);
}
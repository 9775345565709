ion-grid.no-padding.social ion-col {
  position: relative;
  padding-top: 5px;
  padding-bottom: 5px;
}

.square {
  position: relative;
  width: 100%;
  overflow: hidden;
  background-color: var(--ion-color-secondary);

}

.content {
  display: flex;
  align-items: center;
  width: 100%;
  height: 44px;
  border: 1px solid var(--ion-color-secondary);
  border-radius: 1px;
  padding: 0 20px;
}
.soc-icon{
  display: flex;
    align-items: center;
    color: var(--ion-color-white);

}
.soc-icon ion-icon{
  height: 15px;
  width: 15px;
}
.soc-label.small-text {
  color: var(--ion-color-white);
  font-size: 12px;
  display: block;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}



.web .square {
  cursor: pointer;
}

.web .route-social .ion-padding .heading-wrapper,
.web .route-social .ion-padding .action-wrapper {
  padding: 0 32px;
}

.web .route-social .ion-padding .frm-wrapper {
  overflow-y: auto;
}



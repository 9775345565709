.content-spinner {
  display: flex;
  /*width: 100%;
    height: 100%;*/
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 6;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.3);
  border-radius: inherit;
}

.transparent {
  background: rgba(0, 0, 0, 0);
}

.coffee-cup-image{
  height: 120px;
  width: 100%;
  background: transparent url(../../assets/images/coffee-cup.svg ) no-repeat 50% 50%;
}
.users-image{
  height: 150px;
  width: 100%;
  background: transparent url(../../assets/images/users.svg ) no-repeat 50% 50%;
}

.membership-chooser{
  display: grid;
  align-content: space-between;
}

.route-membership-type .mbsc-select-input.mbsc-control {
  display: none;
}

.enter-pin-alert .alert-input {
  text-transform: uppercase;
}
.choose-subscription-type-list ion-item{
  border: 1px solid var(--ion-color-light);
  border-radius: 5px;
  margin-bottom: 5px;
}
.choose-subscription-type-list .subscription-information{
  padding: 18px 0px;
}
.choose-subscription-type-list ion-checkbox {
  margin-left: 10px;
}
.payment-card-input {
  border: 0;
  outline: none;
  flex: 1;
  padding-left: 0;
  font-size: 13px;
}

.scrollable-y.add-card-scroll {
  overflow: unset;
  overflow-y: unset;
}

.web-stripe-input {
  min-height: 48px;
  display: flex;
  flex: 1;
  align-items: center;
  border-bottom: 1px solid
    var(
      --ion-item-border-color,
      var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13)))
    );
}

.web-stripe-input > .__PrivateStripeElement {
  width: 100%;
}

ion-list .card-list ion-item {
  padding-left: 7px;
}

.web-stripe-input-active {
  border-bottom: 2px solid var(--ion-color-primary);
}

.card-add-wrapper .data-picker-input {
  border-bottom: 1px solid;
  border-color: var(
    --ion-item-border-color,
    var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13)))
  );
}

.cards .ion-text-wrap > ion-note {
  color: var(--ion-color-primary);
  font-size: 10px;
}

.route-card-add .ion-padding .absolute-content ion-list ion-item .sectiontitle {
  margin: 4px 0;
}

.route-card-add * {
  line-height: 1 !important;
}

.cards + ion-button.link {
  font-size: var(--okx-small-text-font-size);
}

.card-list ion-item,
.card-list .web-stripe-input  {
  margin-bottom: calc(var(--ion-padding) / 2);
}

.web .scrollable-y.add-card-scroll {
  overflow: hidden;
  overflow-y: auto;
}

.web ion-content.route-card-add {
  --background: transparent none;
}

.web .route-card-add .ion-padding {

}



.web .route-card-add .ion-padding ion-button:first-child {
  display: none;
}

.web .route-card-add .ion-padding ion-button:last-child {
  margin-bottom: 0;
}

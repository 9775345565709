.apply-points-spacer {
  margin: 32px 0;
  height: 170px;
}

.route-apply-points .ion-padding .incrementer-quantity-solo {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}

.route-apply-points .ion-padding .incrementer-quantity-solo .incrementer-quantity-value {
  font-size: var(--okx-big-label-font-size);
}

.route-apply-points .ion-padding .incrementer-decrease,
.route-apply-points .ion-padding .incrementer-incease {
  --border-color: var(--ion-color-secondary);
}

.web .route-apply-points {
  --background: transparent none;
}

.web .route-apply-points .ion-padding {
 
}



.web .route-apply-points .ion-padding .absolute-content .scrollable-y/*,
.web .route-apply-points .ion-padding .absolute-content .flex-min*/ {
  padding: 0 32px;
}

.web ion-modal.modal-classic.apply-points-modal .modal-classic-closer {
  font-size: 20px;
  padding: 11px;
}

.web ion-modal.modal-classic.apply-points-modal .incrementer-decrease,
.web ion-modal.modal-classic.apply-points-modal .incrementer-incease {
  --border-color: var(--ion-color-secondary);
}


.web #main > ion-content.route-membership-terms  > .ion-padding {
padding-right: 0 !important;
}

.web .route-terms  .ion-padding > div {
  overflow-y: auto;
  max-height: 100%;
}


ion-modal.modal-classic.apply-voucher-modal {
  --width: 80%;
  --height: 45%;
  --border-radius: 10px;
}

.web ion-modal.modal-classic.apply-voucher-modal {
  --width: 500px;
  --height: 45%;
}

ion-modal.modal-classic.apply-voucher-modal .modal-classic-wrapper {
  padding: 0;
}

ion-modal.modal-classic.apply-voucher-modal .modal-classic-header {
  background-color: var(--ion-color-secondary);
  padding-left: 20px;
}

ion-modal.modal-classic.apply-voucher-modal .modal-classic-header h3 {
  color: var(--ion-color-white);
  text-transform: uppercase;
}

ion-modal.modal-classic.apply-voucher-modal .modal-classic-closer {
  color: var(--ion-color-white);
}

ion-modal.modal-classic.apply-voucher-modal .modal-classic-content {
  padding: 15px 15px;
}

ion-modal.modal-classic.apply-voucher-modal .modal-classic-content > .flex-row-wrapper {
  height: 100%;
}

.apply-voucher-modal.voucher-modal-content {
  padding: 0 30px;
}

.voucher-item ion-item ion-input {
  font-size: 12px;
}

ion-button.box-button-color {
  --background: var(--ion-color-gray-tint);
}

ion-button.box-button-color-tertiary {
  --background: var(--ion-color-tertiary);
}
.apply-voucher{
  border: 1px solid var(--ion-color-secondary);
}
.apply-voucher-box,.apply-voucher{
  height: 150px;
  margin-right: 2px;
}
.apply-voucher-box img,.apply-voucher img{
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.web .apply-voucher,
.web .apply-voucher-box {
  cursor: pointer;
}
.apply-voucher-box .voucher-card-wrapper svg {
  fill: var(--ion-color-secondary);
}
.voucher-svg{
  height: 100%;
  width: 100%;
}

.apply-voucher-box,.apply-voucher{
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.apply-voucher-box .voucher-card-content,.apply-voucher .voucher-card-content{
  width: auto;
}
.apply-voucher .voucher-card-wrapper{
  border-style: solid;
  border-width: 5px;
  border-color: #1770FF;
  width: 100%;
  height: 100%;
}
.apply-voucher-box .voucher-card-wrapper{
  width: 100%;
  height: 100%;
}
.voucher-icon{
  padding-left: inherit;
}
.voucher-card-content{
  display: -webkit-inline-box;
}
.vaucher-list{
  position: relative;
}
.apply-vouchers-wrapper {
  height: 80%;
}
.apply-vouchers-wrapper .scrollable-y {
  height: 100%;
}
.loyalty-content.scan {
  padding-bottom: 60px;
}

.loyalty-content.scan h2 {
  margin-top: 20px;
  margin-bottom: 5px;
  text-transform: uppercase;
  letter-spacing: 0.044em;
  /* margin-left: 24px; */
}

.loyalty-title-wrap {
  text-align: left;
  padding-left: 30px;
}

.noQrCode {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.qr-holder {
  margin: 0 !important;
}

.qr-holder canvas {
  border: 10px solid #fff;
}

/* end second tab */


.points-tab::after {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: block;
  content: '';
  /* background-image: linear-gradient(#fff 65%, rgba(255,255,255,0) 180%); */
  background-image: linear-gradient(#fff 62.5%, rgba(255, 255, 255, 0) 130%);
}

.verified-content {
  width: 190px;
  margin: 0 auto;
}

.points-tab .normal-text {
  font-weight: bold;
}

#main > ion-content.route-loyalty > .no-padding .loyalty-title-wrap > ion-text:last-child > span {
  font-weight: bold;
}

#main > ion-content.route-loyalty > .no-padding .absolute-content.points-tab img {
  width: 40%;
}

#main > ion-content.route-loyalty > .no-padding .absolute-content.points-tab .points {
  font-size: 4em;
}

.web #main > ion-content.route-loyalty > .no-padding {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto !important;
  border-radius: var(--okx-box-radius);
  box-shadow: 0 0 var(--ion-padding) rgba(0, 0, 0, 0.25);
  height: 80%;
  overflow: hidden;
  background-color: var(--ion-color-white);
}

.web .route-loyalty .tabs {
  position: relative;
  height: 100%;
  border-radius: inherit;
}

.web .route-loyalty .tabs ion-slides,
ion-slide {
  height: calc(100vh - 350px);
  min-height: 350px;
}

.web ion-content.route-loyalty {
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
}

.web ion-segment-button.segment-button-checked {
  border-bottom-color: var(--ion-color-primary);
}

.web .points-tab {
  background: transparent none;
}

/* new */

.loyalty-header {
  padding: 20px;
  display: flex;
  flex-direction: column;
  background-color: var(--ion-color-secondary);
  border-radius: var(--okx-box-radius);
}
.loyalty-header *, loyalty-available-balance * {
  color: var(--ion-color-white);
}

.loyalty-header img {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: 100%;
}
.loyalty-header-content {
  display: flex;
}
.loyalty-header-content .qr-code h5 {
  color: var(--ion-color-white);
}
.loyalty-header-content {
  flex: 1;
  display: flex;
  justify-content: space-between;
}
.loyalty-header-content > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.loyalty-header-content > div > div:first-child {
  margin-bottom: 30px;
}
.loyalty-header .subtitle,
.loyalty-header .normal-text {
  color: var(--ion-color-white);
  display: block;
}
.loyalty-history,
.loyalty-content {
  padding: 0 0 var(--ion-padding) 0;
}
.loyalty-history {
  padding-top: 0;
}
.thin-text {
  opacity: 0.7;
  font-size: 11px !important;
}

.loyalty-rewards-wrapper {
  margin-top: 10px;
  min-height: 70px;
  display: grid;
  background: var(--ion-color-light);
  align-items: center;
  padding: 10px;
}
.loyalty-content-wrapper {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15);

}

.loyalty-content-wrapper .loyalty-content-value {
  font-size: 20px;
  margin: 0;
  font-weight: bold;
}
/* .border-color {
  color: var(--ion-color-border) !important;
} */
.loyalty-content-desc {
  margin-top: 10px ;
}

.route-loyalty .scrollable-y {
  height: 100%;
}

.web .route-loyalty .ion-padding {
  overflow-y: auto !important;
}
.loyalty-reward {
  height: 150px;
  width: 200px;
  background-size: cover;
  border-radius: var(--okx-box-radius);
  position: relative;
  overflow: hidden;
}
.loyalty-rewards-wrapper {
  display: grid;
  gap: 10px;
  grid-auto-flow: column;
  overflow-x: auto;
}
.loyalty-reward-points {
  position: absolute;
  top: 10px;
  right: 0;
  background-color: var(--ion-color-secondary);
  padding: 5px 10px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
.loyalty-reward-points .strong-text {
  color: var(--ion-color-white);
}
.loyalty-reward-name {
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: var(--ion-color-secondary);
  padding: 10px;
  width: 100%;
  text-align: center;
}
.loyalty-reward-name .strong-text {
  color: var(--ion-color-white);
  text-transform: uppercase;
}

.route-loyalty .no-padding .scrollable-y {
  padding: var(--ion-padding) 0;
}

.loyalty-name {
  color: var(--ion-color-white);
  text-align: center;
  margin-bottom: 0px;
}

.loyalty-points {
  font-size: 16px !important;
}

.loyalty-text-12 {
  font-size: 12px !important;
}

.loyalty-text-10 {
  font-size: 10px !important;
}

.transactions-wrapper .title {
  font-size: 37px;
}

.wallet-button {
  display: flex;
  --background: transparent;
  justify-content: center;
  margin: calc(var(--ion-padding) / 2) 0;
}

.web .wallet-button {
  margin: 10px 0 0 0;
}

.wallet-button img {
  position: relative;
  width: 200px;
  height: 44px;
  object-fit: contain;
}

.web .wallet-button img {
  width: 150px;
}

.web .loyalty-content, .loyalty-content-top-padding {
  padding-top: var(--ion-padding);
}
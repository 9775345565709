.okx-validate-input-wrapper {
  display: flex;
  width: 100%;
}

.okx-validate-input-wrapper .okx-validate-input-holder {
  flex: 1 1 auto;
  /* padding-bottom: 5px; */
}

.okx-validate-input-wrapper .okx-validate-input-holder ion-datetime {
  --padding-start: 0;
  --padding-top: 15px;
}
.okx-validate-input-wrapper .okx-validate-input-holder p {
  font-family: var(--ion-default-font-light);
  font-size: 12px;
  font-weight: bold;
  /* margin: 21px 0 0 0; */
  display: flex;
  /* padding-left: 2px; */
  color: inherit;
}

.okx-validate-input-wrapper .okx-validate-input-holder ion-label,
.okx-validate-input-holder > .date-picker-wrapper .date-picker-modal-label {
  font-family: poppins-medium;
  font-size: 12px;
  font-weight: bold;
}

.okx-validate-input-holder > .date-picker-wrapper .date-picker-modal-label {
  font-size: 11px;
}

.okx-validate-input-holder > ion-input {
  --placeholder-color: var(--ion-color-danger) !important;
}

.okx-validate-input-wrapper ion-button {
  flex: 1 0 auto;
  min-width: 32px;
  /* padding-top: 10px; */
}

.date-picker-wrapper.modal .data-picker-input {
  padding-top: 15px;
}

.date-picker-wrapper.modal ion-label {
  font-size: 8px;
}

.date-picker-modal-label {
  font-size: 9px;
  color: var(--ion-color-dark);
  font-weight: normal;
  /* margin-left: 2px; */
  transition: 0.1s ease-in;
}



.web #main > ion-content.route-faq > .ion-padding {
 padding-right: 0!important;
}

.web .route-faq .ion-padding > div {
  overflow-y: auto;
  max-height: 100%;
  padding-right: 8px;
}




.web #main > ion-content.route-privacy > .ion-padding {
  padding-right: 0 !important;
}

.web .route-privacy .ion-padding > div {
  overflow-y: auto;
  max-height: 100%;
  padding-right: 8px;
}



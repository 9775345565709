.flex-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.user-icon {
  padding-right: 10px;
}

.invite-informations {
  word-break: break-word;
  padding-right: 10px;
}

.invite-buttons, .invite-informations {
  display: grid;
}

.invite-buttons .button-solid {
  height: 30px;
  min-width: 130px;
  margin-top: 0px;
}
.invite-buttons ion-icon{
  width: 30px;
  height: 30px;
}

.invite-row {
  border-bottom: 1px solid var(--ion-separator-color);
  height: 80px;
  width: 100%;
}  

.invite-row .input-wrapper {
  justify-content: space-between;
} 
.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.middle {
  flex-grow: 1;
}

ion-item-options .invite-buttons{
  padding-left: var(--ion-padding, 16px);
}

.subscription-desc-wrapper{
  background-color: var(--ion-color-light-tint);
  padding: 10px;
  text-align: start;
  display: flex;
  flex-direction: column;
}
.subscription-disabled-wrapper {
  height: 100%;
}
.subscription-disabled-div {
  padding-top: var(--ion-padding);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 80%;
}
.subscription-disabled-message-web {
  font-style: italic;
}
.subscription-disabled-message {
  padding-top: var(--ion-padding);
  font-style: italic;
}
.subscription-desc-wrapper *{
  align-self: flex-start !important;
}
.subscription-image{
  height: 300px;
  background-size: cover;
  position: absolute;
  width: 100%;
  left: 0;
  background-position: center;
  top: 0;
}
.default-subscription-image{
  background-image: url(../../assets/images/92coffeee_subscription.jpg);
}
.web .subscription-image{
  height: 200px;
}
.subscription-list .inline-input {
  border-bottom: 1px solid var(--ion-color-light);
  margin-bottom: calc(var(--ion-padding) / 2);
}
.subscription-information{
  width: 100%;
}
.subscription-information-description {
  margin: calc(var(--ion-padding) / 2) 0;
}
.subscription-referral-input{
  border: none !important;
}
.select-home-location-modal {
  --height: 210px !important;
}
.subscription-price {
  margin-bottom: var(--ion-padding);
  border-bottom: 1px solid var(--okx-drawer-border-color);
}
.route-membership .absolute-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.route-membership .subtitle {
  font-size: 20px;
}
ion-header.static {
  margin-bottom: -48px;
  z-index: 5;
}

ion-toolbar {
  --border-color: #fff;
  padding: 0 15px;
}
.buttons-first-slot, buttons-last-slot {
  width: 40px;
}
ion-toolbar.primary-toolbar {
  --min-height: 80px;
  --background: var(--ion-color-white) url(../../assets/images/logo-color.png) no-repeat 50% 50% /
  auto 30px;
}
ion-toolbar.toolbar-dark {
  --background: var(--ion-color-secondary) url(../../assets/images/logo-white.png) no-repeat 50% 50% /
  auto 30px;
}

.web ion-header:not(.route-order).static {
  position: absolute;
  right: 0;
  background: transparent none;
  min-height: var(--ion-toolbar-min-height);
  height: auto;
  margin: 0;
  z-index: 6;
  pointer-events: none;
}

.web ion-header.static ion-buttons {
  pointer-events: all;
}

.web ion-toolbar.primary-toolbar {
  --background: transparent url(../../assets/images/logo-color.svg) no-repeat 50% 50% / auto 110%;
}

.web ion-toolbar.primary-toolbar ion-buttons:first-of-type,
.web ion-toolbar.primary-toolbar ion-buttons:last-of-type,
.web ion-toolbar.dynamic-header ion-buttons:first-of-type {
  background-color: rgba(255, 255, 255, 0.5);
}

.web ion-toolbar.primary-toolbar ion-buttons:last-of-type ion-button {
  --padding-top: 12px;
  --padding-end: 12px;
  --padding-bottom: 12px;
  --padding-start: 12px;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  margin-bottom: 0;
  width: 50px;
  height: 50px;
}

.web ion-toolbar.primary-toolbar ion-button.image-button {
  display: none;
}

ion-header.static ion-toolbar:last-child {
  --min-height: var(--ion-toolbar-min-height);
}

ion-button.solo-button {
  --padding-start: 6px;
  --padding-end: 6px;
}

ion-button.basket-button {
  height: 45px;
  --padding: 0px !important;
  --margin: 0px !important; 
}

ion-button.basket-button > div > div > .badge,
ion-button.basket-button > div > div > .badge-small {
  position: absolute;
  bottom: 0;
  width: 20px;
  height: 20px;
  right: -4px;
  z-index: 1;
  padding: 4px 1px;
  font-size: 12px;
  border-radius: 60px;
}

ion-button.basket-button > div > div > .badge-small {
  width: 18px;
  height: 18px;
  right: -2px;
  padding: 2px 0 0 0;
}

ion-button.basket-button > div > .basket-icon-wrapper {
  position: relative;
  top: 5px;
}

ion-button.basket-button > div > div > ion-icon {
  font-size: 25px;
}

/* .primary-toolbar .button {
  color: var(--ion-color-dark);
} */

.image-button {
  flex: 1;
  background: transparent;
  --ripple-color: transparent;
}



.web ion-button.basket-button > div > div > ion-badge.badge-small {
  --ion-color-primary: var(--ion-color-secondary);
  color:var(--ion-color-white);
  font-size: 9px;
  min-width: 15px;
  text-align: center;
  --padding-top: 3px;
  --padding-bottom: 3px;
  --padding-start: 3px;
  --padding-end: 3px;
  width: auto;
  height: auto;
  right: 0;
  border-radius: 8px;
  padding: 3px;
  border: 1px solid var(--ion-color-white);
}

.web ion-button.basket-button > div > div:last-child {
  --ion-color-gray: var(--ion-color-secondary);
}

.web .header-ios ion-toolbar:last-of-type,
.web .header-md ion-toolbar:last-of-type {
  --border-width: 0 0 0;
}

.menu-button {
  cursor: pointer;
  margin-left: 6px;
  width: 20px;
  height: 20px;
}

.tertiary-text {
  color: var(--ion-color-tertiary) !important;
}

.header-points *{
  display: block;
  line-height: 1;
  margin-right: 10px;
}

ion-header ion-toolbar:first-of-type{
  margin-top: var(--ion-safe-area-top, 0);
  padding-top: 0px;
}

.header-back-button {
    width: 40px !important;
    height: 40px !important;
    --border-radius: 50% !important;
    --padding-end: 0 !important;
    --padding-start: 0 !important;
    --background: var(--ion-color-white);
}
.input-avatar {
  display: none;
}

.profile-image-content {
  width: 96px;
  height: 96px;
  margin: auto;
}

.avatar-image-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
}

.avatar-delete {
  position: absolute;
  top: 15%;
  right: 115px;
  transform: translate(0, -50%);
}

.avatar-photo {
  position: absolute;
  top: 50%;
  right: -20px;
  transform: translate(0, -50%);
  background: rgba(var(--ion-color-secondary-rgb), 1);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar-circle {
  border: 10px solid var(--okx-circle-color);
  border-radius: 65px;
  position: relative;
}

.account-inputs .ion-text-wrap > ion-note {
  color: var(--ion-color-primary);
}

.account-inputs ion-button.link {
  font-size: var(--okx-small-text-font-size);
}

.account-inputs ion-input {
  margin-bottom: 15px;
}

.line {
  height: 1px;
  background-color: var(--ion-color-tertiary);
  margin: 20px 0;
}

.top {
  margin-top: 25px;
}


#main > ion-content.route-account > .ion-padding ion-label,
#main > ion-content.route-account > .ion-padding label {
  color: var(--ion-color-gray);
  font-weight: 500;
  font-weight: var(--okx-small-text-font-size)
}

#main > ion-content.route-account > .ion-padding .mbsc-select-input.mbsc-control {
  border-bottom: 1px solid  var(--ion-color-gray);
}

@supports (-webkit-overflow-scrolling: touch) {
  /* iOS */
  .avatar-delete {
    position: absolute;
    top: 15%;
    right: 95px;
    transform: translate(0, -50%);
  }
}
ion-content.route-account ion-input{
  flex: unset;

}
ion-content.route-account ion-input input{
  --padding-top: 0;
  --padding-bottom:0
}

.web #main > ion-content.route-account > .ion-padding {

}

.web .route-account .ion-padding .frm-wrapper,
.web .route-account .ion-padding .top-medium {
  position: absolute;
}

.web .route-account .ion-padding .frm-wrapper {
  left: 32px;
  right: 32px;
  top: 40px;
  bottom: 260px;
  overflow-y: auto;
  margin-bottom: 10px;
}

.web .route-account .ion-padding .top-medium {
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0 32px 32px;
}

.web .route-account .ion-padding ion-button:last-child {
  margin-bottom: 0;
}

.web .avatar-photo,
.web .avatar-delete {
  cursor: pointer;
}

.route-account .has-value.sc-ion-input-ios-h .input-clear-icon.sc-ion-input-ios{
  height: 20px;
  width: 20px;
}

.route-account ion-item {
  height: 60px;
}

.route-account .data-picker-input {
  padding: 5px 0 0 0;
}

.route-account .select-picker-label {
  font-size: 10px;
}

.route-account .mbsc-select-input.mbsc-control {
  padding-top: 6px;
}

.account-footer{
  text-align: center;
  padding-top: 10px;
}

.terms-list ion-item {
  display: flex;
  margin: 0 0 5px 0 !important;
  height: fit-content;
}

.terms-list ion-item div{
  height: 100%;
}

.terms-list ion-item ion-label{
  margin: 0 0 0 10px;
}

.route-account ion-toggle.md {
  padding-top: 7px;
}
.route-apply-loyalty .swiper-slide {
  text-align: left;
}

.route-apply-loyalty .incrementer-quantity-solo {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.route-apply-loyalty .incrementer-quantity-value {
  font-size: 50px;
}
.route-apply-loyalty .incrementer-decrease,
.route-apply-loyalty .incrementer-incease {
  border: 1px solid var(--ion-color-primary);
  border-radius: 4px;
  font-size: var(--okx-title-font-size) !important;
}
.route-apply-loyalty .incrementer-quantity-unit {
  font-family: secondary-bold;
}

.apply-loyalty-wrapper {
  height: calc(100% - 115px);
  overflow: auto;
}

.route-apply-loyalty .incrementer * {
  font-family: secondary-bold !important;
}
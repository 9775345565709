.okx-password-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid var(--ion-color-gray);
}
.okx-password-wrapper ion-input {
  border: none !important;
}
.okx-password-wrapper .okx-password-holder {
  flex: 1 1 auto;
}
.okx-password-wrapper ion-button {
  flex: 0 0 auto;
}
.eye-button {
  margin-right: -3px;
}

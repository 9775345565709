.feedback-label {
  font-size: 12px;
  margin-right: 5px;
}

.star-holder {
  padding: 10px 0px;
}

.star {
  font-size: 20px;
  color: var(--ion-feedback-star);
  margin-right: 15px;
}

.web .star {
  cursor: pointer;
}

.star:last-child {
  margin: 0;
}

.box-holder-top {
  margin-top: 20px;
}

.commentService {
  height: 50px;
}

.commentTech {
  height: 160px;
}
#main > ion-content.route-feedback > .ion-padding {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
#main > ion-content.route-feedback > .ion-padding .box-holder .inline-input:first-child {
  border-bottom: 1px solid var(--ion-color-gray);
}
#main > ion-content.route-feedback > .ion-padding .box-holder .inline-input {
  padding: 10px;
}
#main > ion-content.route-feedback > .ion-padding .inline-input label {
  flex: none;
  font-size: var(--ion-font-size);
  margin-inline-end: 50px;
  margin-top: 0;
}

#main > ion-content.route-feedback > .ion-padding .inline-input:first-child .feedback-label {
  margin: 0;
}

.alert-message.sc-ion-alert-ios {
  color: var(--ion-color-primary);
  font-family: var(--ion-default-font-thiner);
}

#main > ion-content.route-feedback > .ion-padding .mbsc-select-input.mbsc-control {
  border: 0;
}
ion-content.route-feedback .box-holder {
  padding: 0;
}
ion-content.route-feedback .box-holder ion-item {
  --border-color: transparent;
}
ion-content.route-feedback .box-holder ion-list {
  padding: 0;
}
ion-content.route-feedback .box-holder ion-item:first-child {
  border-bottom: 1px solid var(--ion-color-gray);
}
#main
  > ion-content.route-feedback
  > .ion-padding
  .select-picker-input--feedback
  + input.mbsc-select-input.mbsc-control {
  font-size: 12px;
  border-bottom: 1px solid var(--ion-color-gray);
}

#main > ion-content.route-feedback > .ion-padding .top-medium {
  margin-top: 10px;
}

#main > ion-content.route-feedback > .ion-padding .top-medium .inline-input.inline-input--feedback {
  align-items: center;
  padding-top: 10px;
  border-bottom: 0;
}

.web ion-content.route-feedback {
  --background: transparent none;
}

.web
  #main
  > ion-content.route-feedback
  > .ion-padding
  .top-medium
  .inline-input.inline-input--feedback {
  align-items: center;
  padding-top: 10px;
  border-bottom: 0;
}

.web #main > ion-content.route-feedback > .ion-padding {
  overflow-y: auto  !important;
}

.web .route-feedback .ion-padding .frm-wrapper {
  overflow-y: auto;
}

.route-feedback .ion-padding .action-wrapper {
  left: 0;
  right: 0;
  bottom: 0;
}

.web .route-feedback .ion-padding ion-button:last-child {
  margin-bottom: 0;
}
.route-feedback ion-checkbox {
  margin: auto 20px;
}

.select-location-wrapper {
  position: relative;
  margin-bottom: 10px;
}

.route-feedback .tab-buttons ion-button {
  max-width: 60%;
  min-width: 40%;
  width: auto;
}

.submit-feadback-button{
  min-height: 42px;
}
@media screen and (max-width: 380px) {
  .route-feedback .tab-buttons {
    flex-direction: column !important;
  }
}
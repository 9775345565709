.table-number-input {
  border-bottom: 1px solid;
  border-bottom-color: var(--okx-border-color);
}

#main > ion-content.route-order-to-table .select-picker-label,
#main > ion-content.route-order-to-table .time-picker-label {
  color: var(--ion-color-primary);
  font-size: 12px;
  font-family: primary-bold;
}

.web ion-content.route-order-to-table {
  background-size: 100% auto;
  background-position: 100% 0;
  background-repeat: no-repeat;
}

.click-collect-wrapper {
  height: 100%;
}

.click-collect-selectors {
  margin: 50px 0 40px 0;
}

.click-collect-button {
  position: absolute;
  bottom: 20px;
  width: calc(100vw - 40px);
  left: 20px;
}

.click-collect-layout {
  height: 100%;
}

.no-padding {
  height: 100%;
}
.web .click-collect-wrapper {
  height: fit-content;
}

.web .click-collect-button {
  position: sticky;
  width: unset;
  margin: unset;
  left: 0;
}

.web .no-padding {
  height: fit-content;
}

.table-number {
  display: flex;
  height: 100px;
  align-items: center;
  padding: var(--ion-padding);
}
.route-order-to-table .click-collect-dialog-content{
  padding: 0;
}
